import React from "react";
import "./categoryOther.scss";
import classNames from "classnames";
import { Link } from "gatsby";
import {Title} from "../../elements/Title/title";

export const CategoryOther = props => {
    const otherCategory = props.otherCategory;
    const classnames = classNames(`m-related`, {

    });

    return (
        <div className={props.className ? props.className + ` ` + classnames : classnames}>
            <Title className={`marg-b16`} tag={`h2`} size16 content={props.title} />
            <ul>
                {otherCategory.map((value, key) => (
                    <li  key={key}>
                        <Link to={`/${value.slug}/`}>{value.name}</Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CategoryOther;