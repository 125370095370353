import React from "react";
import "./cardFeed.scss";
import Image from "../../modules/Image/image.js";
import Icon from "../../elements/Icon/icon.js";
import classNames from "classnames";
import Column from "../../structure/Column/column";
import {Text} from "../../elements/Text/text";
import {Button} from "../../elements/Button/button";

export const CardFeed = props => {
    const products = props.products;
    const productMeta = props.productMeta;
    const textStatic = props.textStatic;
    const classnames = classNames(`m-cardfeed`, {

    });

    return (
        <div className={props.className ? props.className + ` ` + classnames : classnames}>
            <Column row>
                {Object.keys(products).map((key) => {
                    return(
                        <Column className={"m-cardfeed__item"} row>
                            <Column col="two-fifths" colSm="full" className={`marg-b32 m-cardfeed--img`}>
                                <Image filename={products[key].image_logo} alt={products[key].name} />
                            </Column>
                            <Column col="three-fifths" colSm="full" row>
                                <Column className={"m-cardfeed--info"}>
                                    <p className={"m-cardfeed--info-label"}>{products[key].category_name} {products[key].bank_name}</p>
                                    <Text semibold content={products[key].short_name}/>
                                </Column>
                                <Column col={"half"} className={"m-cardfeed--info"}>
                                    <p className={"m-cardfeed--info-label"}>{textStatic.card_cost_rate_title}</p>
                                    <Text semibold content={`${products[key].cost_rate}%`}/>
                                </Column>
                                <Column col={"half"} className={"m-cardfeed--info"}>
                                    <p className={"m-cardfeed--info-label"}>{textStatic.card_cost_admin_title}</p>
                                    <Text semibold content={`${productMeta.currency_symbol}${products[key].cost_admin}`}/>
                                </Column>
                                <Column col={"half"}>
                                    {
                                        Object.keys(products[key].transmitter).map(function(d){
                                            return <Icon className={`marg-r5`} icon={products[key].transmitter[d]["slug"] + ".png"} height={`35`} />
                                        })
                                    }
                                </Column>
                                <Column col={"half"}>
                                    <Button primary
                                            className={`marg-b10`}
                                            link={`/${products[key].slug}/`}
                                            content={`${textStatic.more_info}`}
                                            linkTitle={products[key].name}
                                    />
                                </Column>
                            </Column>
                        </Column>
                    )}
                )}
            </Column>
        </div>
    );
};