import React from "react";
import Layout from "../../components/layout/layout.js";
import Module from "../../components/structure/Module/module.js";
import Wrapper from "../../components/structure/Wrapper/wrapper.js";
import Column from "../../components/structure/Column/column.js";
import Box from "../../components/modules/Box/box.js";
import Seo from "../../components/seo/seo.js";
import {Title} from "../../components/elements/Title/title";
import {Breadcrumbs} from "../../components/modules/Breadcrumbs/breadcrumbs";
import Faq from "../../components/modules/Faq/faq";
import WidgetBanks from "../../components/modules/WidgetBanks/widgetBanks.js";
import JsonBreadcrumbs from "../../components/seo/ld-json/jsonBreadcrumbs.js";
import CardFilterFeed from "../../components/modules/CardFilterFeed/cardFilterFeed.js";
import CategoryOther from "../../components/modules/CategoryOther/categoryOther.js";

function CardCategory(props) {
    const dataCardCategory = props.pageContext.data;
    const seo = props.pageContext.seo;
    const productMeta = props.pageContext.productMeta;
    const textStatic = props.pageContext.textStatic;
    const otherCard = props.pageContext.otherCard;
    const asks = props.pageContext.asks;
    const otherCategory = props.pageContext.otherCategory;

    const filterProps = { otherCard, textStatic , productMeta };

    return (
        <Layout>
            <Seo lang={seo.lang} title={seo.title} url={seo.url} image={seo.image} description={seo.description} robotsIndex={seo.robotsIndex} robotsFollow={seo.robotsFollow}/>
            <Module bgPr>
                <Wrapper white>
                    <Breadcrumbs content={dataCardCategory.breadcrumbs} host={seo.host}/>
                    <JsonBreadcrumbs data={dataCardCategory.breadcrumbs} host={seo.host}/>
                    <Column row>
                        <Column col="fifth-complete" colSm="half" colXs="full">
                            <Title tag={`h1`} size20 content={seo.mainTitle}/>
                        </Column>
                    </Column>
                    <Column row>
                        <Column col="forth-complete" colSm="full">
                            <Box bgPr borderTop>

                                <div className={`marg-t30`}>

                                    <CardFilterFeed {...filterProps} />

                                </div>

                                {otherCategory && (
                                    <CategoryOther className={`marg-t30`} title={textStatic.category_other_title} otherCategory={otherCategory}/>
                                )}

                                <Faq className={`marg-t30`} asks={asks}/>

                            </Box>
                        </Column>
                        <Column col="forth" colSm="full">
                            <Box bgPr>
                                <WidgetBanks/>
                            </Box>
                        </Column>
                    </Column>

                </Wrapper>

            </Module>
        </Layout>
    )
}
export default CardCategory;