import React, { useState } from "react";
import "./cardFilterFeed.scss";
import {CardFeed} from "../CardFeed/cardFeed.js";

function CardFilterFeed(props) {

    const textStatic = props.textStatic;

    const [checkedTransmitter, setCheckedTransmitter] = useState([]);

    const handleCheckedTransmitter = e => {
        const tr_data_slug = e.target.dataset.id;
        let newCheckedTransmitter = checkedTransmitter.filter(item => item !== tr_data_slug);
        if (e.target.checked) newCheckedTransmitter.push(tr_data_slug);
        setCheckedTransmitter(newCheckedTransmitter);
    };

    let transmitter_data = [];

    props.otherCard.forEach( element_card => {
        element_card.transmitter.forEach( trd => {
            if (transmitter_data[trd.slug] === undefined) {
                transmitter_data[trd.slug] = [];
                transmitter_data[trd.slug]["slug"] = trd.slug;
                transmitter_data[trd.slug]["name"] = trd.name;
            }
        });
    });

    let cards_f = props.otherCard.filter(card => {

        let check_in_array = false;

        Object.keys(card.transmitter).forEach(function(d){
            if (!check_in_array) {
                check_in_array = checkedTransmitter.includes(card.transmitter[d].slug)
            }
        });

        return (
            check_in_array
        );
    });

    cards_f =  cards_f.length > 0 ? cards_f : props.otherCard;

    return (
        <>
            <div className={"m-card-filter"}>
                <div className={"m-card-filter--label"}>{textStatic.filter_result_title}</div>
                {
                    Object.keys(transmitter_data).map(function(d){
                        return (
                            <div>
                                <label key={transmitter_data[d]["slug"]}>
                                    <input type="checkbox" data-id={transmitter_data[d]["slug"]} onClick={handleCheckedTransmitter} /> {transmitter_data[d]["name"]}
                                </label>
                            </div>
                        )
                    })
                }
            </div>

            <CardFeed products={cards_f} textStatic={props.textStatic} productMeta={props.productMeta}/>

        </>
    );
}

export default CardFilterFeed;